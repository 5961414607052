/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Header from "./header"
import "./layout.scss"
import {Trans} from "react-i18next";
import CoronaModal from "./modal";
import DsgvoModal from "./dsgvo-modal";


const Layout = (props) => {

        let className = "site-container"
        if (props.className) className += ` ${props.className}`

        return (
            <>
            <div
                className={className}
                id="page-top">
                <Header hide={props.hideHeader} header={props.header} bgImage={props.bgImage}/>
                <main>{props.children}</main>
                <footer className="bg-light py-5 footer">
                    <section className="page-section" id="footer">
                        <div className="container">
                            <div className="row align-bottom justify-content-center">
                                <div className="col-lg-6 text-left">
                                    <h2 className="text-primary mt-0"><Trans>Öffnungszeiten</Trans></h2>
                                    <table className="table table-borderless table-sm">
                                        <tbody>
                                        <tr>
                                            <th scope="row"><Trans>Montag</Trans></th>
                                            <td>08.00 - 11.30 <Trans>Uhr</Trans> <Trans>und</Trans> 16.00 - 17.30 <Trans>Uhr</Trans></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><Trans>Dienstag</Trans></th>
                                            <td>08.00 - 11.30 <Trans>Uhr</Trans></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><Trans>Mittwoch</Trans></th>
                                            <td>08.00 - 11.30 <Trans>Uhr</Trans></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><Trans>Donnerstag</Trans></th>
                                            <td>08.00 - 11.30 <Trans>Uhr</Trans> <Trans>und</Trans> 16.00 - 17.30 <Trans>Uhr</Trans></td>
                                        </tr>
                                        <tr>
                                            <th scope="row"><Trans>Freitag</Trans></th>
                                            <td>08.00 - 11.30 <Trans>Uhr</Trans></td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                                <div className="col-lg-6 text-left">
                                    <h2 className="text-primary mt-0"><Trans>Kontakt</Trans></h2>
                                    Dr. med. Elke Natascha Heinitz<br/>
                                    Bei der Kirche 3<br/>
                                    23749 Grube<br/>
                                    <hr/>
                                    <p>
                                        <i className="fa fa-phone"></i> <a href={"tel:043641011"}> 04364/1011</a><br/>
                                        <i className="fa fa-envelope"></i> <a href={"mailto:info@hausarztpraxis-grube.de"}>info@hausarztpraxis-grube.de</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="container">
                        <div className="small text-center text-white"><a className={"text-white"} href={"/impressum/"}>Impressum und Datenschutz</a> &copy; Hausarztpraxis Dr. med. Elke Natascha Heinitz </div>
                    </div>
                </footer>
            </div>
            </>
        )
}

export default Layout
