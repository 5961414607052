import {Button, Modal} from "react-bootstrap";
import {useState} from "react";
import React from "react"
import JsCookie from "js-cookie";
import {instanceOf} from "prop-types";
import {graphql, Link, StaticQuery} from "gatsby";
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {options} from "./header";

export const query = graphql`
    query {
        file: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(width: 125) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`
function Logo() {

    return (
        <>
            <Link {...options} className="text-center navbar-brand js-scroll-trigger" to="/">
                <StaticQuery query={query} render={data =>
                        <Img alt={"Logo"} className={"logo"} fixed={data.file.childImageSharp.fixed}/>
                }
                />
            </Link>
        </>
    );
}
export default Logo