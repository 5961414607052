import {Button, Modal} from "react-bootstrap";
import {useState} from "react";
import React from "react"

function DsgvoModal(props) {
    const [show, setShow] = useState(props.show);

    const handleClose = () => {
        props.action();
    };
    const handleNo = () => {
        if(typeof window !== 'undefined'){
            window.open("https://idana.app/WPZZ65", "_blank")
        }
    };
    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header>
                    <Modal.Title>Haben Sie den Patienten-Check-in schon abgeschlossen?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Wir brauchen Ihre Einwilligung um Ihre Daten verarbeiten zu können.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Ja, weiter
                    </Button>
                    <Button variant="light" onClick={handleNo}>
                        Zum Patienten-Check-in
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default DsgvoModal