import {Button, Modal} from "react-bootstrap";
import {useState} from "react";
import React from "react"
import JsCookie from "js-cookie";
import {instanceOf} from "prop-types";
function CoronaModal() {
    const [show, setShow] = useState(JsCookie.get("corona") == undefined);

    const handleClose = () => {
        JsCookie.set("corona", "yes",{ expires: 7 })
        setShow(false)
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Corona Hinweise</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Liebe Patienten,
                    <br/>
                    <br/>
                    wenn Sie Krankheitszeichen wie Husten, Fieber, Schnupfen, Verlust des Geruchs- oder Geschmackssinns, Halsschmerzen, Kopf- und Gliederschmerzen, Kurzatmigkeit oder allgemeine Schwäche haben, kann dies auf eine Infektion mit dem Coronavirus SARS-CoV-2 hinweisen. Auch wenn Ihnen nicht bekannt ist, dass Sie Kontakt zu einer an COVID-19 erkrankten Person hatten, können Sie sich unwissentlich angesteckt haben. Denn eine Person kann das Coronavirus schon Tage vor Krankheitsbeginn übertragen oder auch gar keine Krankheitszeichen entwickeln.
                    <br/><br/>
                    Dann bitte unter <a href={"tel:043641011"}> 04364/1011</a> anrufen statt ins Wartezimmer zu kommen!
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default CoronaModal