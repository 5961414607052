import React from "react"
import {Navbar, Nav, Container, Row, Col} from "react-bootstrap"
import {graphql, Link, StaticQuery} from "gatsby";

import defaultBg from '../images/team.webp'
import Logo from "./logo";
import DsgvoModal from "./dsgvo-modal";

/*export const options = {bg:"url("+logo+")\n" +
        "    center / auto 20%   /!* position / size *!/\n" +
        "    no-repeat        /!* repeat *!/\n" +
        "    fixed            /!* attachment *!/\n" +
        "    padding-box      /!* origin *!/\n" +
        "    content-box      /!* clip *!/\n" +
        "    white            /!* color *!/",
    cover:true, duration: 1.5,  direction:"top"}*/
export const options = {};

export default function Header(props) {
    const [modalShow, setModalShow] = React.useState(false);
    let data = props.data;
        return (
            <>

                <Navbar className="navbar navbar-expand-lg navbar-light fixed-top m-auto" id="mainNav" expand="lg"
                        collapseOnSelect={true}>
                    <div className="container">
                        <Logo/>
                        <Navbar.Text className="justify-content-end d-none d-lg-inline">
                            <i className="fa fa-phone"></i>&nbsp; <a href={"tel:043641011"}> 04364/1011</a>
                            &nbsp;&nbsp;
                            <i className="fa fa-envelope"></i>&nbsp; <a
                            href={"mailto:info@hausarztpraxis-grube.de"}> info@hausarztpraxis-grube.de</a>
                        </Navbar.Text>

                        <Navbar.Toggle aria-controls="navbarResponsive"/>
                        <Navbar.Collapse className="justify-content-end" id="navbarResponsive">
                            <Nav as={"ul"} className="navbar-nav ml-auto my-2 my-lg-0 justify-content-end">
                                <li className="nav-item">
                                    <Nav.Item  className={"nav-link"}><Link {...options} to={"/checkin"}>Check-In</Link></Nav.Item>
                                </li>
                                <li className="nav-item">
                                    <Nav.Item  className={"nav-link"}><Link {...options} to={"/team"}>Team</Link></Nav.Item>
                                </li>
                                <li className="nav-item">
                                    <Nav.Item  className={"nav-link"}><Link {...options} to={"/raeume"}>Unsere Räume</Link></Nav.Item>
                                </li>
                                <li className="nav-item">
                                    <Nav.Item  className={"nav-link"}><Link {...options} to={"/kontakt"}>Anfahrt</Link></Nav.Item>
                                </li>
                                <li className="nav-item">
                                    <Nav.Item className={"nav-link"}><Link {...options} to={"/links"}>Links</Link></Nav.Item>
                                </li>

                                <li className="nav-item">
                                    <Nav.Link className={"appointment-nav"} onClick={(e) => {
                                        e.preventDefault();
                                        setModalShow(true);
                                    }}>
                                        <i className="fas fa-calendar-alt"></i> Termin vereinbaren</Nav.Link>
                                </li>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                    <Container className={"d-lg-none"}>
                        <Row>
                            <Col xs={12}>
                                <i className="fa fa-phone"></i>&nbsp; <a href={"tel:043641011"}> 04364/1011</a>
                            </Col>
                            <Col xs={12}>
                                <i className="fa fa-envelope"></i>&nbsp; <a
                                href={"mailto:info@hausarztpraxis-grube.de"}> info@hausarztpraxis-grube.de</a>
                            </Col>
                        </Row>
                    </Container>
                </Navbar>
                {!props.hide &&
                    <StaticQuery
                        query={graphql`
                                  query {
                                     team: file(relativePath: { eq: "team.jpg" }) {
                                          childImageSharp {
                                              fluid(maxWidth: 2000, fit:CONTAIN) {
                                                  ...GatsbyImageSharpFluid
                                              }
                                          }
                                      }
                                  }
                                `}
                        render={data => (
                            <img src={data.team.childImageSharp.fluid.src} width={"400"} height={"800"}  alt={"Team"} className={"headerImage"} sizes={data.team.childImageSharp.fluid.sizes} srcSet={data.team.childImageSharp.fluid.srcSet}/>
                        )
                        }
                    />}

                <DsgvoModal action={()=>{
                    window.open("https://order.medidate.org/?pid=7d704ae9-388e-464c-ac2b-c6fa928cf9c0&ptok=596e394243754c5051633669373978736151646337326672774a755444704b7657302f61555648796471656f794d2f7a79747530722f6e7a657842707a7253505449586947674c6f65316a423376744e6152485a4a46473552564436476f72616f553765446a574545786c64626e75726831496e6f537141584251446a556e6c66666c52776f6a622b46525143466e38314a3649522f476f7236776c34674130674472737133304f79774d3d");
                }} show={modalShow} onHide={()=>{setModalShow(false)}}></DsgvoModal>
            </>
        );
    }



